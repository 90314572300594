import * as React from 'react';
import {PositionComponent} from '../_PositionComponent';

export default () => <PositionComponent
    showOverview={false}
    title={'Senior Computer Vision Engineer - Self-driving cars'}
    aboutPositionTitle={'The Platform'}
    aboutPosition={`
       We're building a precise location service, specifically targeting the self-driving vehicle market.
       Precision localization for Autonomous Vehicles (AVs) is a critical problem that remains unsolved and
       is quickly becoming a roadblock to general deployment of AVs at scale and increasing the safety of
       existing highly automated vehicles. Our unique solution easily plugs into existing AV architectures
       and achieves an accuracy of a few centimeters with costs a fraction of competing techniques,
       all using existing automotive hardware found on L2 vehicles.
    `}
    aboutRole={`
        We are looking for a bright engineer to lead our computer vision efforts, working on cutting edge, large scale localization problems.
        <br/><br/>
        You will be working closely with Domain experts with over 5 decades of applied precision localization experience
        to revolutionize the way self-driving cars detect and understand their position in 3D space to within 1 centimeter.
        <br/>
        You will be part of our RnD team, working closely with Computer Vision and Engineering Teams based in San Francisco.
    `}
    blocks={[
        { title: 'What you’ll work on',
            list: [
                'Solving computer vision focused problems in real time on board a vehicle',
                'Working closely with Computer Vision Team based in San Francisco, comprised of Computer Vision, Signal Processing and Sensor Fusion experts, on implementing computer vision algorithms and modifying them to run properly on low cost automotive grade hardware',
                'Surveying and understanding the current state of the art, staying up to date with changes in the field, rapidly prototyping and evaluating algorithm effectiveness using quantitative benchmarks',
                'Understanding trades between classical and learning based computer vision approaches and when to use which',
            ]
        },
        { title: 'What You Have',
            list: [
                'Strong CS background',
                'Experience with C++ 11/14',
                'Experience with Python or Matlab for prototyping',
                'Experience in machine learning',
                'Experience with Structure from Motion, Visual Inertial Odometry, Semantic Segmentation and classical computer vision algorithms',
                'Experience with real time algorithm implementation',
                'Advanced English',
            ]
        },
        { title: 'Would be a plus',
            list: [
                'Masters or PhD in Computer Science',
                'Experience with C++ 11/14',
                'Experience with Eigen, OpenCV, Sophus, Bazel',
            ]
        },
    ]}
/>;
